<template>
  <div class="container-fluid padding-top-layout">
    <div class="wrap">
      <div class="wrap__block">
        <div class="wrap__block__header wrap__header">
          <div class="wrap__block__header__content">
            <span>{{ $t('idea_db_source.title_idea_db_source_management') }}</span>
          </div>
        </div>
        <div class="wrap__status">
          <div class="wrap__status__item">
            <img src="@/assets/icons/clock.svg" alt="" />
            <div class="wrap__status__item__wrap">
              <p>{{$t('common.last_update')}}</p>
              <p>{{ dateTimeFormat(statusUpdate.time) }}</p>
            </div>
          </div>
          <div class="wrap__status__item">
            <img src="@/assets/icons/userTable.svg" alt="" />
            <div class="wrap__status__item__wrap">
              <p>{{$t('common.updated_by')}}</p>
              <p>{{ statusUpdate.user }}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="category-table main-table custom-table new-register view-table"
        :class="[isFullScreen && 'full-screen', isExpand ? 'expanded' : 'no-expanded']"
      >
        <data-table
          :data-source="ideaSources"
          :grid-columns="gridColumns"
          :init-grid="initializeGrid"
          :rowFocus="1"
          :showFocus="false"
          :allowAddNew="false"
          :isShowToolbar="true"
          :isResizeCustome="false"
          :dataIdeaDbSourceList="dataIdeaDbSourceList"
          :ideaDbId="ideaDbIdModel"
          @changeFullScreen="onChangeFullScreen"
          @updateValueIdeaId="updateValueIdeaId"
          @onNavigateToCreateDbSource="onNavigateToCreateDbSource"
          :isEmptySource="isEmptySource"
          :labelEmpty="messageLabelEmpty"
        />
      </div>
    </div>
  </div>
</template>
  
  <script>
import { mapActions, mapState } from 'vuex';
import { CollectionView } from '@mescius/wijmo';
import _ from 'lodash';
import DataTable from '@/components/category/data-table';
import { getListIdeaDbSourceDetailsApi, updateHistoryCurrentUser, getIdeaDbSourceDetailsApi } from '@/api/ideaDbSource';
import {formatDateTime} from "@/utils/datetimeFormat";
import { ROUTES } from '@/router/constants';
import { KEYS_CODE } from '@/constants/keyboard';
import * as wjGrid from '@mescius/wijmo.grid';
import { getWidthByTextContent, setMinMaxSizeColumns } from '@/utils/calcTextWidth';
import { formatNumberByConditions } from '@/utils/convertNumber';

export default {
  name: 'ideaDbSourceList',
  props: {
  },
  data() {
    return {
      items: [
        {
          text: this.$t('idea_db_source.hyperlink_home'),
          disabled: false,
          href: ROUTES.HOME_DASHBOARD,
        },
        {
          text: this.$t('idea_db_source.hyperlink_setting'),
          disabled: false,
          href: ROUTES.SETTING,
        },
        {
          text: this.$t('idea_db_source.label_manage_db_source'),
          disabled: false,
          href: ROUTES.LIST_PRODUCTS,
        },
      ],
      listIdeaSourceDetails: [],
      listHeaders: [],
      flex: null,
      ideaSources: null,
      gridColumns: [],
      showTooltip: false,
      isEmptySource: true,
      ideaDbIdModel: null,
      dataIdeaDbSourceList: [],
      statusUpdate        : {
        time: null,
        user: null
      },
      dataOriginal: [],
      itemLoadMoreNumber: 3000,
      lengthdataOriginal: null
    };
  },
  components: {
    DataTable
  },
  async mounted() {
    await this.updateBreadCrumb(this.items);
    const dataIdeaDbSource = await getListIdeaDbSourceDetailsApi();
    if (dataIdeaDbSource && dataIdeaDbSource.data) {
      this.dataIdeaDbSourceList = dataIdeaDbSource.data;
      if(this.dbSourceId && dataIdeaDbSource.data.find(item => item.id === this.dbSourceId)) {
        this.ideaDbIdModel = this.dbSourceId;
      } else {
        this.ideaDbIdModel = this.dataIdeaDbSourceList[0].id;
      }
      await this.fetchDataDetailsSource(this.ideaDbIdModel);
    }
    this.defineTable();
  },
  computed: {
    ...mapState('commonApp', ['isExpand']),
    ...mapState('registerData', ['isFullScreen']),
    ...mapState('userData', ['currentUser', 'dbSourceId']),

    messageLabelEmpty() {
      return this.$t('idea_db_source.description_source_details_empty');
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb', 'actionUpdateIsLoadingTable']),
    onNavigateToCreateDbSource() {
      return this.$router.push({ path: ROUTES.REGISTER_DB_SOURCE });
    },
    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/icons/${image}`);
      }
      return '';
    },
    dateTimeFormat(dateTime) {
          return formatDateTime(dateTime);
    },
    async fetchDataDetailsSource(dbId) {
      this.actionUpdateIsLoadingTable(true)
      const ideaDbSource = await getIdeaDbSourceDetailsApi(dbId);
      if(ideaDbSource?.db_source_detail.length > 0) {
        this.listHeaders = ideaDbSource.header;
        this.statusUpdate = {
          time: ideaDbSource.latest_record.updated_at_latest,
          user: ideaDbSource.latest_record.user_updated
        };

        this.dataOriginal = ideaDbSource.db_source_detail;
        this.lengthdataOriginal = this.dataOriginal.length;
        this.prepareData(ideaDbSource.db_source_detail, this.itemLoadMoreNumber);
        this.listIdeaSourceDetails = ideaDbSource.db_source_detail.slice(0, Math.min(this.lengthdataOriginal, this.itemLoadMoreNumber));
        this.ideaSources = new CollectionView([...this.listIdeaSourceDetails], {
        });
        this.isEmptySource = false;
        // CALL API update lasted visit
        const params = {
            id: dbId
        }
        updateHistoryCurrentUser(params);
      } else {
        //Handle case not found data details
        this.isEmptySource = true;
        this.listHeaders = ideaDbSource.header || [];
        this.ideaSources = new CollectionView([], {
        });
      }
      this.actionUpdateIsLoadingTable(false)
    },
    async updateValueIdeaId(id) {
      await this.fetchDataDetailsSource(id);
      this.gridColumns = this.getGridColumns();
      this.ideaSources.refresh();
      this.flex.columnGroups = this.getGridColumns();
    },
    addDataLoadMore(data, cnt) {
      // lengthdataOriginal always better than cnt
			let more = this.prepareData(this.dataOriginal.slice(this.listIdeaSourceDetails.length, this.listIdeaSourceDetails.length + Math.min(this.lengthdataOriginal - cnt, cnt)), Math.min(this.lengthdataOriginal - cnt, cnt));
      // TODO: refactor: data = data.concat(more);
			for (let i = 0; i < more.length; i++) {
				data.push(more[i])
			}
      this.itemLoadMoreNumber += this.itemLoadMoreNumber
      if (this.flex) {
        setMinMaxSizeColumns(this.flex, this.listIdeaSourceDetails);
      }
		},
    prepareData(ideaDbSource, cnt) {
      let max = Math.min(this.lengthdataOriginal, cnt);
      for (let i = 0; i < max; i++) {
          for (let j = 0; j < this.listHeaders.length; j++) {
            const parseContent = JSON.parse(ideaDbSource[i].content);
            const indexSourceValue = j === 2; // To handle format num index source value
            ideaDbSource[i][`header_${j}`] = indexSourceValue
            ? formatNumberByConditions(parseContent[j], { isRealNumber: true })
            : parseContent[j];
          }
      }
      return ideaDbSource;
    },
    initializeGrid(flexgrid) {
      this.flex = flexgrid;
      flexgrid.scrollPositionChanged.addHandler((s) => {
				if (s.viewRange.bottomRow >= s.rows.length - 1 && this.listIdeaSourceDetails.length < this.lengthdataOriginal) {
          let view = s.collectionView;
          let index = view.currentPosition; // keep position in case the view is sorted
          this.addDataLoadMore(this.listIdeaSourceDetails, this.itemLoadMoreNumber);
          this.ideaSources = new CollectionView([...this.listIdeaSourceDetails], {
          });
					view.refresh();
          view.currentPosition = index;
				}
			});

      document.addEventListener('keydown', (event) => {
        if (
          (event.metaKey || event.ctrlKey) &&
          [KEYS_CODE.DOWN_ARROW, KEYS_CODE.UP_ARROW, KEYS_CODE.LEFT_ARROW, KEYS_CODE.RIGHT_ARROW].includes(
            event.keyCode,
          )
        ) {
          event.preventDefault();
        }
      });

      flexgrid.hostElement.addEventListener(
        'keydown',
        (event) => {
          if (event.metaKey || event.ctrlKey) {
            if (event.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = flexgrid.selection;
              const cellRange = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col);
              flexgrid.selection = cellRange;

              // re-select after add more
              setTimeout(() => {
                flexgrid.selection = cellRange;
              }, 200);
            } else if (event.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection = new wjGrid.CellRange(0, currentSelection.col);
            } else if (event.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1);
            } else if (event.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection = new wjGrid.CellRange(currentSelection.row, 1);
            }
          }
        },
        false,
      );
      flexgrid.hostElement.addEventListener('mouseover', (e) => {
        if (e.target.parentElement.classList.contains('comment-icon')) {
          e.target.src = require(`@/assets/icons/comment_active.svg`);
        }
      });
      flexgrid.hostElement.addEventListener('mouseout', (e) => {
        if (e.target.parentElement.classList.contains('comment-icon')) {
          e.target.src = require(`@/assets/icons/comment.svg`);
        }
      });
      this.flex.onSelectionChanged(null);
      flexgrid.beginningEdit.addHandler((s, e) => {
        let column = s.columns[e.col];
        if (column.binding !== 'data_id') {
          e.cancel = true;
        }
      });
    },
    changeTooltipImage(event, type) {
      if (type === 'mouseleave') {
        event.target.src = this.getSettingIcon('helper-icon.svg');
        event.target.parentElement.style.cursor = 'default';
        this.showTooltip = false;
      } else {
        event.target.src = this.getSettingIcon('helper-icon_active.svg');
        event.target.parentElement.style.cursor = 'pointer';
        this.showTooltip = false;
      }
    },
    defineTable() {
      this.gridColumns = this.getGridColumns();
      this.ideaSources = new CollectionView([...this.listIdeaSourceDetails], {
      });
      this.flex.columnGroups = this.getGridColumns();
      setMinMaxSizeColumns(this.flex, this.listIdeaSourceDetails);
      this.ideaSources.currentItem = null;
      this.isEmptySource = this.ideaSources?.items?.length <= 0;
      this.$nextTick(() => {
        this.scrollToTop();
      });
    },
    getGridColumns() {
      this.listHeaders[0] = this.$t('idea_db_source.title_header_source_name')
      this.listHeaders[1] = this.$t('idea_db_source.title_header_unit')
      this.listHeaders[2] = this.$t('idea_db_source.title_header_value_source')
      this.listHeaders[3] = this.$t('idea_db_source.title_header_unit_source')
      const mapColumns    = this.listHeaders.map((header, index) => {
        const getWidth = getWidthByTextContent(header);
        return  ({
          header      : header,
          binding     : `header_${index}`,
          width       : index === this.listHeaders.length - 1 ? '*' : getWidth + 10, // 30 is width of the filter button
          minWidth    : getWidth + 10, // 30 is width of the filter button
          maxWidth    : 980,
          allowSorting: false,
          isRequired  : false,
          cssClass    : header.cssClass,
          isReadOnly  : header.isReadOnly,
          wordWrap    : true,
          cssClassAll : "single-row"
        })
      });
      return [
        {
          header: '#',
          binding: 'id',
          allowSorting: false,
          isReadOnly: true,
          visible: false,
        },
        ...mapColumns,
      ];
    },
    scrollToTop() {
      if (!this.flexGrid) {
        return;
      }

      let rc = this.flexGrid.cells.getCellBoundingRect(0, 0, true);
      this.flexGrid.scrollPosition = new wjcCore.Point(this.flexGrid.scrollPosition.x, -rc.top);
    },
    onChangeFullScreen(isFullScreen) {
      if (isFullScreen) {
        this.scrollToTop();
      } else {
        this.initialView();

        this.$nextTick(() => {
          this.scrollToTop();
        });
      }
    },
    closePopup() {
      this.commentPopup = false;
      this.historyPopup = false;
    },
    minWidthDetailsSource(index, header) {
      switch (index) {
        case 0:
          return 128;
          break;
        case 1:
          return 114
          break;
        case 2:
          return 74
          break;
      
        default:
          return getWidthByTextContent(header) + 5
          break;
      }
    }
  },
};
</script>
  <style scoped lang="scss">
@import './styles/index.scss';
.template-detail-header {
  .wj-btn {
    display: none;
  }
}

.wj-flexgrid .wj-cell-maker {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $monoBlack;
  // font-family: 'Source Han Sans JP';
}

.wj-cell {
  .wj-cell-maker {
    background: none;
    justify-content: start;
  }

  .wj-cell-maker:hover {
    background: none;
    color: $monoBlack;
  }
}

.duration-wrapper {
  .duration-text {
    color: $monoBlack;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    margin-bottom: 8px;
  }

  .calculation-page {
    display: flex;
    align-items: center;
    background-color: $bgLight;
    padding: 15px;
    border-radius: 4px;
    height: 56px;

    div {
      margin-right: 20px;

      .duration-content {
        color: $monoBlack;
        font-weight: 700;
        font-size: 11px;
        line-height: 26px;
        letter-spacing: 0.03em;
        padding: 5px 0px 0px 0px;
      }

      .duration-text {
        color: $goldMid;
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 0.03em;
      }
    }
  }
}

.template-detail-header {
  .wj-cell-maker {
    margin-top: 2px;
    width: 26px;
    height: 20px;
    font-style: normal;
    // font-family: 'Source Han Sans JP';
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    color: $goldMid;
    text-align: center;
    background: none;
    border: 1px solid $goldLight;
  }

  .wj-cell-maker:hover {
    color: $monoWhite;
    background: #0072a3;
  }
}
.hide-filter {
  .wj-btn {
    display: none;
  }
}
.custom-table {
  padding-left: 20px !important;
}

.return {
  &__button-responsive {
    &.fixed-button-bottom {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 99;
      top: unset;
      .item-responsive {
        width: 90% !important;
      }
    }
    width: calc(100% + 40px);
    display: flex;
    flex-direction: column;
    background: $bgMid;
    box-shadow: 0px -3.13px 2.86674px rgba(160, 181, 186, 0.165596), 0px -1.24px 1.13351px rgba(160, 181, 186, 0.24);
    padding: 16px 20px;
    position: relative;
    bottom: -80px;
    margin-left: -20px;
    .item-responsive {
      width: 100%;
      margin: 0 auto;
      .common-btn {
        width: 100% !important;
        .common-btn-text {
          margin-left: 10px;
        }
        .addregistericon_active {
          display: none;
        }
        .addregistericon_disabled {
          display: none;
        }
      }
      .register-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &_warp {
          display: flex;
          // gap: 8px;
        }
        &_pulldown {
          transition: all 0.3s ease-out;
          .btn-icon {
            display: block;
          }
          .btn-icon_hover {
            display: none;
          }
          &.open-pulldown {
            transform: rotate(180deg);
          }
        }
        &:hover {
          background-color: $monoWhite !important;
          .register-button_warp {
            span {
              color: $monoBlack;
            }
          }
          .register-button_pulldown {
            .btn-icon {
              display: none;
            }
            .btn-icon_hover {
              display: block;
            }
          }
        }
      }
      &.register-btn {
        .register-pulldown {
          width: 100%;
          .pulldown-item {
            width: 100%;
          }
        }
      }
    }
  }
}

.register-btn {
  position: relative;
  .register-pulldown {
    position: absolute;
    width: 238px;
    max-height: 160px;
    background: $monoOffWhite;
    margin-top: 2px;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
      0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
      0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    z-index: 98;
    .item-pulldown {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 7px 0px 9px 16px;
      border: 1px solid rgba(42, 42, 48, 0.1);
      color: $monoBlack;
      border-bottom: 0;
      cursor: pointer;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: 'Source Han Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      margin-left: unset !important;
      &:hover {
        background: $bgLight;
      }
    }
    .pulldown-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 7px 16px 9px;
      gap: 8px;
      width: 230px;
      height: 40px;
      border-bottom: 1px solid rgba(42, 42, 48, 0.1);
      cursor: pointer;
      &:last-child {
        border-bottom: unset;
      }
      &:hover {
        background: $bgLight;
      }
      .pulldown-text {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.03em;
        color: $monoBlack;
      }
      &.disabled {
        pointer-events: none;
        cursor: default;
        background: $monoLight !important;
        .pulldown-text {
          color: $monoMid;
          font-weight: 500;
        }
      }
    }
  }
  .register-reduction-target-sm {
    justify-content: center !important;
  }
}
.return__header {
  background: $monoOffWhite;
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
    0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
    0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-width: 103px !important;
  padding: 0 !important;
  height: 40px;

  .register-button_warp {
    gap: 8px;
    .return-active {
      display: none;
    }
  }

  &:hover {
    .register-button_warp {
      .return {
        display: none;
      }
      .return-active {
        display: block;
      }
    }
    background: $goldLight;

    svg path {
      stroke: #ffffff;
    }

    .return__text {
      color: $monoOffWhite;
    }
  }

  &:active {
    .register-button_warp {
      .return {
        display: block;
      }
      .return-active {
        display: none;
      }
    }
  }

  .action {
    cursor: pointer;
  }

  .return__text {
    // font-family: 'Source Han Sans JP';
    color: #404d50;
    font-size: 14px;
    letter-spacing: 0.03em;
    font-style: normal;
    line-height: 24px;
    font-weight: 500;
  }
}
</style>
  
  